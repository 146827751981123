import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './SharePanel.module.css';

function CopyNotification() {
  return (
    <div className={css.notificationContainer}>
      <div className={css.notificationSubContainer}>
        <div className={css.successAlert}>
          <FormattedMessage id="SharePanel.CopyNotification.copyToClipboard" />
        </div>
      </div>
    </div>
  )
}

export default CopyNotification;
