import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import ExternalLink from '../ExternalLink/ExternalLink';
import css from './SharePanel.module.css';
import CopyNotification from './CopyNotification';

const SharePanel = ({ location, className, rootClassName, config }) => {
  const intl = useIntl();
  const copy = useRef(null);
  const rootUrl = config.marketplaceRootURL;
  const value = rootUrl + location;
  const classes = classNames(rootClassName || css.root, className);
  const [showCopyNotification, setShowCopyNotification] = useState(false);

  const shareTextString = intl.formatMessage({ id: 'SharePanel.shareText' });
  const shareText = encodeURIComponent(shareTextString);

  const emailLink = `mailto:?subject=${shareText}&body=${value}`;
  const whatsappLink = `https://wa.me/?text=${shareText}+${value}`;
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${value}&quote=${shareText}`;
  const twitterLink = `https://twitter.com/share?text=${shareText}&url=${value}`;

  const copyToClipBoard = () => {
    copy.current.select();
    copy.current.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand('copy');
    setShowCopyNotification(true);
  };

  useEffect(() => {
    if (showCopyNotification) {
      const timeout = setTimeout(() => {
        setShowCopyNotification(false);
      }, 1500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showCopyNotification]);

  return (
    <div className={classes}>
      {showCopyNotification && <CopyNotification />}
      <h2 className={css.modalTitle}>
        <FormattedMessage id="SharePanel.modalTitle" />
      </h2>

      <div className={classNames(css.shareGroup, css.hideOnMobile)}>
        <h3 className={css.shareGroupTitle}>
          <FormattedMessage id="SharePanel.pageLinkLabel" />
        </h3>
        <div className={css.copyLinkWrapper}>
          <input ref={copy} value={value} readOnly />
          <button className={css.copyLinkButton} onClick={copyToClipBoard}>
            <svg width="18" height="20" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.727,0l-10.909,0c-1.004,0 -1.818,0.814 -1.818,1.818l0,12.727l1.818,0l0,-12.727l10.909,0l0,-1.818Z" />
              <path d="M15.455,3.636l-10,0c-1.005,0 -1.819,0.814 -1.819,1.819l0,12.727c0,1.004 0.814,1.818 1.819,1.818l10,-0c1.004,-0 1.818,-0.814 1.818,-1.818l-0,-12.727c-0,-1.005 -0.814,-1.819 -1.818,-1.819Zm-0,14.546l-10,-0l-0,-12.727l10,-0l-0,12.727Z" />
            </svg>
          </button>
        </div>
      </div>

      <div className={classNames(css.copyLinkMobile, css.hideOnDesktop)}>
        <a href="#" className={css.copyLinkButton} onClick={copyToClipBoard}>
          <svg width="33" height="33" viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.668,11.732c3.781,3.785 3.729,9.853 0.023,13.579c-0.007,0.008 -0.015,0.016 -0.023,0.024l-4.252,4.252c-3.751,3.751 -9.853,3.75 -13.603,0c-3.751,-3.75 -3.751,-9.853 0,-13.603l2.348,-2.348c0.623,-0.623 1.695,-0.209 1.727,0.671c0.041,1.122 0.242,2.249 0.614,3.337c0.125,0.368 0.035,0.776 -0.24,1.051l-0.828,0.828c-1.774,1.773 -1.829,4.661 -0.073,6.452c1.773,1.809 4.688,1.819 6.475,0.032l4.253,-4.251c1.784,-1.784 1.776,-4.668 -0,-6.444c-0.235,-0.234 -0.471,-0.416 -0.655,-0.543c-0.264,-0.181 -0.427,-0.477 -0.439,-0.797c-0.025,-0.669 0.211,-1.358 0.74,-1.887l1.332,-1.332c0.35,-0.349 0.898,-0.392 1.303,-0.109c0.464,0.324 0.898,0.688 1.298,1.088Zm8.919,-8.919c-3.75,-3.75 -9.852,-3.751 -13.603,-0l-4.252,4.252c-0.008,0.008 -0.016,0.016 -0.023,0.024c-3.706,3.726 -3.758,9.794 0.023,13.579c0.4,0.4 0.834,0.764 1.298,1.088c0.405,0.283 0.953,0.24 1.303,-0.109l1.332,-1.333c0.528,-0.528 0.765,-1.217 0.74,-1.886c-0.012,-0.32 -0.175,-0.616 -0.439,-0.797c-0.185,-0.127 -0.421,-0.309 -0.655,-0.543c-1.776,-1.776 -1.784,-4.66 0,-6.444l4.253,-4.251c1.787,-1.787 4.702,-1.777 6.475,0.032c1.756,1.791 1.701,4.678 -0.073,6.452l-0.828,0.828c-0.276,0.275 -0.365,0.683 -0.24,1.051c0.371,1.088 0.573,2.215 0.614,3.337c0.032,0.88 1.104,1.293 1.727,0.671l2.348,-2.348c3.751,-3.75 3.751,-9.853 -0,-13.603Z"
              fill="#4a4a4a"
            />
          </svg>
          <p className={css.shareButtonText}>
            <FormattedMessage id="SharePanel.linkLabel" />
          </p>
        </a>
      </div>

      <div className={css.shareGroup}>
        <h3 className={css.shareGroupTitle}>
          <FormattedMessage id="SharePanel.socialShareLabel" />
        </h3>
        <div className={css.shareButtonsWrapper}>
          <ExternalLink
            href={whatsappLink}
            className={classNames(css.shareButton, css.shareButtonWhatsapp, css.hideOnDesktop)}
          >
            <div className={css.shareButtonIcon}>
              <svg width="44" height="44" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M37.41,6.394c-4.115,-4.125 -9.596,-6.394 -15.42,-6.394c-12.021,-0 -21.803,9.782 -21.803,21.804c-0,3.84 1.001,7.592 2.907,10.901l-3.094,11.295l11.56,-3.035c3.182,1.739 6.767,2.652 10.42,2.652l0.01,-0c12.012,-0 22.01,-9.782 22.01,-21.804c0,-5.824 -2.475,-11.294 -6.59,-15.419Zm-15.42,33.55c-3.261,-0 -6.453,-0.874 -9.232,-2.524l-0.658,-0.393l-6.855,1.797l1.826,-6.688l-0.432,-0.688c-1.817,-2.887 -2.769,-6.217 -2.769,-9.644c-0,-9.989 8.132,-18.121 18.13,-18.121c4.842,0 9.389,1.886 12.807,5.313c3.418,3.428 5.52,7.975 5.51,12.817c-0,9.999 -8.338,18.131 -18.327,18.131Zm9.939,-13.573c-0.54,-0.275 -3.221,-1.592 -3.722,-1.768c-0.501,-0.187 -0.864,-0.275 -1.228,0.275c-0.363,0.55 -1.404,1.768 -1.728,2.141c-0.314,0.363 -0.639,0.412 -1.179,0.137c-3.201,-1.601 -5.303,-2.858 -7.415,-6.482c-0.56,-0.962 0.56,-0.894 1.601,-2.976c0.177,-0.363 0.088,-0.677 -0.049,-0.952c-0.138,-0.275 -1.228,-2.957 -1.68,-4.047c-0.442,-1.061 -0.893,-0.913 -1.227,-0.933c-0.315,-0.02 -0.678,-0.02 -1.041,-0.02c-0.364,0 -0.953,0.138 -1.454,0.678c-0.501,0.55 -1.905,1.866 -1.905,4.547c-0,2.682 1.954,5.275 2.219,5.638c0.275,0.363 3.841,5.863 9.311,8.23c3.457,1.493 4.813,1.621 6.541,1.365c1.051,-0.157 3.222,-1.316 3.673,-2.592c0.452,-1.277 0.452,-2.367 0.315,-2.593c-0.128,-0.246 -0.491,-0.383 -1.032,-0.648Z"
                  fill="#25d366"
                />
              </svg>
            </div>
            <p className={css.shareButtonText}>
              <FormattedMessage id="SharePanel.whatsappLabel" />
            </p>
          </ExternalLink>
          <ExternalLink
            href={emailLink}
            className={classNames(css.shareButton, css.shareButtonEmail)}
          >
            <div className={css.shareButtonIcon}>
              <svg width="24" height="18" viewBox="0 0 24 18" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M23.545,5.944c0.183,-0.146 0.455,-0.01 0.455,0.22l0,9.586c0,1.242 -1.008,2.25 -2.25,2.25l-19.5,0c-1.242,0 -2.25,-1.008 -2.25,-2.25l0,-9.581c0,-0.235 0.267,-0.366 0.455,-0.221c1.05,0.816 2.442,1.852 7.223,5.325c0.989,0.722 2.658,2.241 4.322,2.232c1.673,0.014 3.375,-1.538 4.327,-2.232c4.781,-3.473 6.168,-4.514 7.218,-5.329Zm-11.545,6.056c1.088,0.019 2.653,-1.369 3.441,-1.941c6.22,-4.514 6.693,-4.907 8.128,-6.032c0.272,-0.211 0.431,-0.539 0.431,-0.886l-0,-0.891c-0,-1.242 -1.008,-2.25 -2.25,-2.25l-19.5,0c-1.242,0 -2.25,1.008 -2.25,2.25l0,0.891c0,0.347 0.159,0.67 0.431,0.886c1.435,1.12 1.908,1.518 8.128,6.032c0.788,0.572 2.354,1.96 3.441,1.941Z"
                  fill="#0973fb"
                />
              </svg>
            </div>
            <p className={css.shareButtonText}>
              <FormattedMessage id="SharePanel.emailLabel" />
            </p>
          </ExternalLink>
          <ExternalLink
            href={facebookLink}
            className={classNames(css.shareButton, css.shareButtonFacebook)}
          >
            <div className={css.shareButtonIcon}>
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M24.146,12.073c0,-6.669 -5.404,-12.073 -12.073,-12.073c-6.669,-0 -12.073,5.404 -12.073,12.073c-0,6.026 4.415,11.021 10.187,11.927l-0,-8.437l-3.067,0l-0,-3.49l3.067,0l-0,-2.66c-0,-3.025 1.801,-4.697 4.56,-4.697c1.321,0 2.702,0.236 2.702,0.236l0,2.969l-1.522,0c-1.5,0 -1.968,0.931 -1.968,1.886l0,2.266l3.349,0l-0.536,3.49l-2.813,0l0,8.437c5.772,-0.906 10.187,-5.901 10.187,-11.927Z"
                  fill="#4267b2"
                />
              </svg>
            </div>
            <p className={css.shareButtonText}>
              <FormattedMessage id="SharePanel.facebookLabel" />
            </p>
          </ExternalLink>
          <ExternalLink
            href={twitterLink}
            className={classNames(css.shareButton, css.shareButtonTwitter)}
          >
            <div className={css.shareButtonIcon}>
              <svg width="24" height="20" viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.533,4.858c0.015,0.213 0.015,0.426 0.015,0.639c0,6.503 -4.949,13.995 -13.995,13.995c-2.786,0 -5.375,-0.807 -7.553,-2.208c0.396,0.046 0.777,0.061 1.188,0.061c2.299,0 4.416,-0.776 6.106,-2.101c-2.162,-0.046 -3.974,-1.462 -4.599,-3.411c0.305,0.045 0.61,0.076 0.929,0.076c0.442,-0 0.884,-0.061 1.295,-0.168c-2.254,-0.457 -3.944,-2.436 -3.944,-4.827l-0,-0.061c0.654,0.365 1.416,0.594 2.223,0.624c-1.325,-0.883 -2.193,-2.391 -2.193,-4.096c0,-0.914 0.244,-1.752 0.67,-2.483c2.421,2.985 6.061,4.934 10.142,5.148c-0.076,-0.366 -0.122,-0.746 -0.122,-1.127c0,-2.711 2.193,-4.919 4.919,-4.919c1.416,0 2.696,0.594 3.594,1.553c1.112,-0.213 2.178,-0.624 3.122,-1.188c-0.366,1.143 -1.142,2.102 -2.162,2.711c0.989,-0.106 1.949,-0.381 2.832,-0.761c-0.67,0.974 -1.508,1.842 -2.467,2.543Z"
                  fill="#1da1f2"
                />
              </svg>
            </div>
            <p className={css.shareButtonText}>
              <FormattedMessage id="SharePanel.twitterLabel" />
            </p>
          </ExternalLink>
        </div>
      </div>
    </div>
  );
};

SharePanel.defaultProps = {};

export default SharePanel;
